<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5>恭喜您， 🎉 {{ data.name }}</h5>
    <b-card-text class="font-small-3">
      您获得了本月销售冠军！
    </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">
      <b-link><!--${{ kFormatter(data.saleToday) }}-->¥298,000</b-link>
    </h3>
    <b-button
      variant="primary"
    >
      浏览订单
    </b-button>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
